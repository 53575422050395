import React from "react";
import { Link, graphql } from "gatsby";
import get from "lodash/get";
import SEO from "../components/seo";
import Layout from "../components/layout";
import CarerPreview from "../components/carer-preview";
import Section from "../components/section";
import Button from "../system/button";
import Img from "gatsby-image";

import CarerGrid from "../components/carer-grid";

import styles from "./index.module.scss";
import carerStyles from "./carers.module.scss";
import IconText from "../system/icon-text";

import cx from "classnames";
const newMemberListStyles = cx(carerStyles.list, styles.new_list);

class RootIndex extends React.Component {
  render() {
    const carersCount = get(
      this,
      "props.data.allContentfulCaregiver.totalCount"
    );
    let carers = get(this, "props.data.allContentfulCaregiver.edges");
    const recentJoiners = carers.slice(carersCount - 3, carersCount);

    const carerAvatars = carers.map(({ node }) => {
      const { fullName, slug, carerImage } = node;
      return { fullName, slug, carerImage };
    });

    return (
      <Layout location={this.props.location}>
        <SEO />
        <Section sectionId="welcome" hero sectionClassName={styles.heroSection}>
          <h1 className={styles.landing_title}>Wellington Community Carers</h1>
          <h3 className={styles.landing_subtitle}>
            A support network of {carersCount} local independent carers for you
            and your loved ones
          </h3>
          <div className={styles.trustBadges}>
            <ul className={styles.badges}>
              <li>
                <IconText reverse icon="checkmark">
                  DBS Checked
                </IconText>
              </li>
              <li>
                <IconText reverse icon="checkmark">
                  Fully Vaccinated
                </IconText>
              </li>
              <li>
                <IconText animate reverse icon="checkmark">
                  Public insurance
                </IconText>
              </li>
              <li>
                <IconText reverse icon="checkmark">
                  Caring
                </IconText>
              </li>
            </ul>
          </div>
          <p className={styles.landing_about}>
            We're a caring group of local care providers affiliated with
            Somerset County Council. We enable our clients to remain independent
            and in their own home while providing professional, reassuring care.
            All our members are DBS checked, have public liability insurance and
            go through an assurance process to join the network.
          </p>
        </Section>
        <Section>
          <h4>Our network</h4>
          <p className={styles.network_about}>
            The network was founded in 2018 and has grown from 3 to{" "}
            {carersCount} since then. We all work as individuals with different
            backgrounds and stories of how we became carers. But we're joined
            together by a love of what we do and work together to provide
            consistent care for all our clients.
          </p>
          <div className={styles.network_grid}>
            <CarerGrid />
          </div>
          <div className={styles.network_buttons}>
            <Button className={styles.network_button} size="s" to="/carers">
              Get to know the carers
            </Button>
            {/* <Button size="s" type="outline" to="/service">
              Learn about our services
            </Button> */}
          </div>
        </Section>
        <Section>
          <h4 className={styles.new_title}>Meet our newest members</h4>{" "}
          <ul className={newMemberListStyles}>
            {" "}
            {recentJoiners.map(({ node }) => {
              return (
                <li key={`recent_${node.slug}`}>
                  <CarerPreview carer={node} />{" "}
                </li>
              );
            })}{" "}
          </ul>{" "}
        </Section>
        {/* <section>
          <h4>Some words from our clients</h4>
        </section> */}
      </Layout>
    );
  }
}

export default RootIndex;

export const pageQuery = graphql`
  query HomeQuery {
    site {
      siteMetadata {
        title
      }
    }
    allContentfulCaregiver(
      sort: { order: ASC, fields: memberSince }
      filter: { status: { eq: "active" } }
    ) {
      totalCount
      edges {
        node {
          fullName
          memberSince(formatString: "MMM YY")
          slug
          status
          memberLocation
          memberRadius
          carerImage {
            fluid(
              cropFocus: FACE
              resizingBehavior: FILL
              quality: 100
              maxWidth: 300
              maxHeight: 300
            ) {
              ...GatsbyContentfulFluid
            }
          }
          about {
            about
          }
        }
      }
    }
  }
`;
